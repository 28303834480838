import { FC } from 'react';
import Link from 'next/link';
import styles from './HeaderBanner.module.scss';

const HeaderBanner: FC = () => {
  return (
    <div className={styles.litresHeaderBanner}>
      <Link prefetch={false} className={styles.litresHeaderBanner__link} target="_blank" href="https://www.litres.ru/audiobook/edvard-radzinskiy/babe-carstvo-russkiy-paradoks-71699818/">
        <img width="1920" height="60" className={styles.litresHeaderBanner__img} src="/images/banners/paradox-1920x60.jpg" alt="banner" />
        <img width="450" height="100" className={`${styles.litresHeaderBanner__img} ${styles.litresHeaderBanner__img_mobile}`} src="/images/banners/paradox-450x100.jpg" alt="banner" />
      </Link>
      {/* <Link prefetch={false} className={styles.litresHeaderBanner__link} target="_blank" href="https://www.litres.ru/genre/webtoon-275083/">
        <img width="1920" height="60" className={styles.litresHeaderBanner__img} src="/images/banners/k-webtune-1920x60.jpg" alt="banner" />
        <img width="450" height="100" className={`${styles.litresHeaderBanner__img} ${styles.litresHeaderBanner__img_mobile}`} src="/images/banners/k-webtune-450x100.jpg" alt="banner" />
      </Link> */}
    </div>
  );
};

export default HeaderBanner;
